import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
// import { useTranslation } from 'react-i18next';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';

import { SectionTitle } from '../utils/GlobalFormatting';

// import { breakpoint } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(40)} auto 0;
  padding-bottom: ${rem(80)};
`;

// const Section = styled.div`
//   justify-content: center;
//   /* margin-bottom: ${rem(40)};

//   ${breakpoint('large')} {
//     margin-bottom: ${rem(50)};
//   } */
// `;

// const Department = styled(SectionTitle)`
//   font-size: ${rem(20)};
//   letter-spacing: 0.01em;
//   padding-top: 1.1rem;
//   border-top: 2px solid ${(props) => props.theme.palette.colors.sixt};

//   ${breakpoint('mediumlarge')} {
//     font-size: ${rem(22)};
//   }

//   ${breakpoint('large')} {
//     font-size: ${rem(24)};
//   }
// `;

// const StaffCol = styled(Col)`
//   margin: 0 0 ${rem(30)};
//   width: 100%;

//   ${breakpoint('medium')} {
//     width: 50%;
//   }

//   ${breakpoint('mediumlarge')} {
//     width: 33.333%;
//   }

//   ${breakpoint('large')} {
//     width: 25%;
//   }
// `;

function SuccessPage() {
  // const { t } = useTranslation();

  // useLangRedirect(page);

  return (
    <Layout>
      <SEO title="Orderbekräftelse" />

      <Hero
        heading="Tack för din beställning!"
        // image={featuredMedia?.localFile}
        pageTitle
        // preHeading="Pre heading"
        // actions="Actions"
        tint
      >
        Du har gjort ett bra val och stöttat din förening i en svår tid.
      </Hero>

      <Container>
        <Row>
          <Col>
            <SectionTitle>Vad händer nu?</SectionTitle>
            <h3>1. Din publikfigur produceras</h3>
            <p>
              Din publikfigur är nu skickad för tryck och kommer produceras
              enligt dina anpassningar.
            </p>
            <h3>2. Leverans & montering</h3>
            <p>
              När din publikfigur är färdig kommer vi köra ut den till arenan
              för montering.
            </p>
            <p>Detta sker inom 7 arbetsdagar.</p>
            <h3>3. Återvinning</h3>
            <p>
              När säsongen är slut tar vi vårt miljöansvar och återvinner alla
              publikfigurer. Vid andra frågor gällande er publikfigur inför
              säsongsavslut kontakta er klubb.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default SuccessPage;
